<template>
  <v-container>
    <h2 class="mb-4">Synthesis of Alum - Results</h2>

    <p class="mb-3">
      Fill in the table below using the data you collected throughout the experiment and any
      required calculations.
    </p>

    <v-simple-table style="max-width: 591px" class="ml-n4">
      <thead>
        <tr>
          <td>
            <stemble-latex content="$\text{Property}$" />
          </td>
          <td style="text-align: center">
            <stemble-latex content="$\text{Value}$" />
          </td>
        </tr>
        <tr>
          <td>a) Mass of Al used (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massAl"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>b) Moles of Al used (mol)</td>
          <td>
            <calculation-input
              v-model="inputs.molAl"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>c) Theoretical yield of alum (g)</td>
          <td>
            <calculation-input
              v-model="inputs.theoYield"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>d) Actual mass of alum collected (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massAlum"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>e) Percent Yield (%)</td>
          <td>
            <calculation-input
              v-model="inputs.pctYield"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>
      </thead>
    </v-simple-table>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'SynthesisOfAlumReportSheet',
  components: {
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massAl: null,
        molAl: null,
        theoYield: null,
        massAlum: null,
        pctYield: null,
      },
    };
  },
};
</script>

<style></style>
